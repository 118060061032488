import React from 'react'
import { BreakpointProvider, setDefaultBreakpoints } from 'react-socks'

import '@fontsource/montserrat/300.css'
import '@fontsource/montserrat/300-italic.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/400-italic.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/500-italic.css'
import '@fontsource/rubik/600.css'
import '@fontsource/rubik/700.css'
import './src/styles/tailwind.css'
import { GatsbyBrowser } from 'gatsby'

setDefaultBreakpoints([
  { xs: 0 },
  { sm: 641 },
  { md: 769 },
  { lg: 1025 },
  { xl: 1281 },
])

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return (
    <BreakpointProvider>{element}</BreakpointProvider>
  )
}

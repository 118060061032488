exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-asiakastili-tsx": () => import("./../../../src/pages/asiakastili/[...].tsx" /* webpackChunkName: "component---src-pages-asiakastili-tsx" */),
  "component---src-pages-ehdot-maksuehdot-tsx": () => import("./../../../src/pages/ehdot/maksuehdot.tsx" /* webpackChunkName: "component---src-pages-ehdot-maksuehdot-tsx" */),
  "component---src-pages-ehdot-palautusehdot-tsx": () => import("./../../../src/pages/ehdot/palautusehdot.tsx" /* webpackChunkName: "component---src-pages-ehdot-palautusehdot-tsx" */),
  "component---src-pages-ehdot-tietosuoja-tsx": () => import("./../../../src/pages/ehdot/tietosuoja.tsx" /* webpackChunkName: "component---src-pages-ehdot-tietosuoja-tsx" */),
  "component---src-pages-ehdot-toimitusehdot-tsx": () => import("./../../../src/pages/ehdot/toimitusehdot.tsx" /* webpackChunkName: "component---src-pages-ehdot-toimitusehdot-tsx" */),
  "component---src-pages-ehdot-tsx": () => import("./../../../src/pages/ehdot.tsx" /* webpackChunkName: "component---src-pages-ehdot-tsx" */),
  "component---src-pages-hallinta-tsx": () => import("./../../../src/pages/hallinta/[...].tsx" /* webpackChunkName: "component---src-pages-hallinta-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-koirakuiskaaja-tsx": () => import("./../../../src/pages/koirakuiskaaja.tsx" /* webpackChunkName: "component---src-pages-koirakuiskaaja-tsx" */),
  "component---src-pages-koirankoulutus-verkossa-tsx": () => import("./../../../src/pages/koirankoulutus-verkossa.tsx" /* webpackChunkName: "component---src-pages-koirankoulutus-verkossa-tsx" */),
  "component---src-pages-koirankoulutus-verkossa-verkkokurssit-tsx": () => import("./../../../src/pages/koirankoulutus-verkossa/verkkokurssit.tsx" /* webpackChunkName: "component---src-pages-koirankoulutus-verkossa-verkkokurssit-tsx" */),
  "component---src-pages-kouluttajaksi-tsx": () => import("./../../../src/pages/kouluttajaksi.tsx" /* webpackChunkName: "component---src-pages-kouluttajaksi-tsx" */),
  "component---src-pages-kouluttajat-tsx": () => import("./../../../src/pages/kouluttajat.tsx" /* webpackChunkName: "component---src-pages-kouluttajat-tsx" */),
  "component---src-pages-lahjakortti-tsx": () => import("./../../../src/pages/lahjakortti.tsx" /* webpackChunkName: "component---src-pages-lahjakortti-tsx" */),
  "component---src-pages-meille-tulee-pentu-tsx": () => import("./../../../src/pages/meille-tulee-pentu.tsx" /* webpackChunkName: "component---src-pages-meille-tulee-pentu-tsx" */),
  "component---src-pages-palvelut-tsx": () => import("./../../../src/pages/palvelut.tsx" /* webpackChunkName: "component---src-pages-palvelut-tsx" */),
  "component---src-pages-pre-info-tsx": () => import("./../../../src/pages/preInfo/[...].tsx" /* webpackChunkName: "component---src-pages-pre-info-tsx" */),
  "component---src-pages-review-tsx": () => import("./../../../src/pages/review/[...].tsx" /* webpackChunkName: "component---src-pages-review-tsx" */),
  "component---src-pages-tuotteet-tsx": () => import("./../../../src/pages/tuotteet.tsx" /* webpackChunkName: "component---src-pages-tuotteet-tsx" */),
  "component---src-pages-varaa-aika-tsx": () => import("./../../../src/pages/varaa-aika.tsx" /* webpackChunkName: "component---src-pages-varaa-aika-tsx" */),
  "component---src-pages-yhteystiedot-tsx": () => import("./../../../src/pages/yhteystiedot.tsx" /* webpackChunkName: "component---src-pages-yhteystiedot-tsx" */),
  "component---src-templates-article-list-tsx": () => import("./../../../src/templates/articleList.tsx" /* webpackChunkName: "component---src-templates-article-list-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-online-training-tsx": () => import("./../../../src/templates/onlineTraining.tsx" /* webpackChunkName: "component---src-templates-online-training-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */),
  "component---src-templates-trainer-tsx": () => import("./../../../src/templates/trainer.tsx" /* webpackChunkName: "component---src-templates-trainer-tsx" */),
  "component---src-templates-webinar-list-tsx": () => import("./../../../src/templates/webinarList.tsx" /* webpackChunkName: "component---src-templates-webinar-list-tsx" */),
  "component---src-templates-webinar-tsx": () => import("./../../../src/templates/webinar.tsx" /* webpackChunkName: "component---src-templates-webinar-tsx" */)
}

